import { API } from "./config"

import { getUser } from "../../services/auth"
import axios from 'axios'

export const signIn = async (email, password) => {
  return await fetch(`${API}/auth/sign-in`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((response) => {
      return response
    })
    .catch((err) => {
      //console.log(err)
      return err
    })
}

export const register = async (data) => {
  console.log(data)
  let requestData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(data),
  }
  return await fetch(`${API}/auth/sign-up`, requestData).catch(err => {
    console.log(err)
  })
}

export const uploadRiderDocuments = async (formData, onProgress = () => {}) => {
  console.log(getUser().userId)

  return await axios({
    headers: {
      Authorization: `Bearer ${getUser().token}`
    }, 
    method: 'post', 
    url: `${API}/riders/document/${getUser().userId}`, 
    data: formData, 
    onUploadProgress: onProgress
  }).then(response => response).catch(err => err)

  /*
  return await fetch(`${API}/riders/document/${getUser().userId}`, {
    headers: {
      Authorization: `Bearer ${getUser().token}`,
    },
    method: "POST",
    body: formData,
  })
    .then((response) => {
      return response
    })
    .catch((err) => {
      return err
    }) */
}

export const getUserInformation = async (user, token) => {
  return await fetch(`${API}/user/${user}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  })
    .then((response) => {
      console.log('may response')
      return response
    })
    .catch((err) => {
      console.log('may error')
      console.log(err)
      return err
    })
}
